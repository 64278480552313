<template>
	<apply-detail :is-refund="false" />
</template>

<script>
import ApplyDetail from './components/applyInfo'

export default {
	name: 'RefundsForm', // 退货退款
	components: {
		ApplyDetail
	}
}
</script>
