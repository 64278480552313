<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mrefund-apply">
				<div class="mapply-group">
					<div class="item" v-if="isRefund" @click="changeGoodsStatus">
						<div class="item-next">
							<span v-if="refundForm.goods_status>0" class="black">{{refundForm.goods_status | statusFilter($t('mobile.options.cargoStatus'))}}</span>
							<span v-else>{{ $t('mobile.placeholder.select') }}</span>
							<i class="el-icon-arrow-right"></i>
						</div>
						<div class="item-label">{{ $t('mobile.refund.cargoStatus') }}<em>*</em></div>
					</div>
					<div class="item" @click="changeRefundReason">
						<div class="item-next">
							<span v-if="refundForm.reason" class="black">{{ refundForm.reason }}</span>
							<span v-else-if="!isRefund || refundForm.goods_status>0">{{ $t('mobile.placeholder.select') }}</span>
							<span v-else>{{ $t('mobile.refund.cargoStatusSelect') }}</span>
						</div>
						<div class="item-label">{{ $t('mobile.refund.reason') }}<em>*</em></div>
					</div>
					<div class="item">
						<div class="item-label">{{ $t('mobile.refund.money') }}<em>*</em></div>
						<div class="item-block">
							<el-input v-model="refundForm.price" :disabled="isRefund && refundForm.goods_status < 2" ref="refundPrice" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" @change="changeRefundPrice">
								<template slot="prepend">￥</template>
								<template slot="append" v-if="!isRefund || refundForm.goods_status > 1">
									<span @click="handleEditPrice"><i class="el-icon-edit"></i>{{ $t('mobile.actions.edit') }}</span>
								</template>
							</el-input>
							<div class="tip" v-if="!isRefund || refundForm.goods_status == 2">{{$t('mobile.refund.editPriceTip1')}}￥{{ goodsInfo.order_amount }}，{{$t('mobile.refund.editPriceTip3')}}￥0.00</div>
							<div class="tip" v-else>{{$t('mobile.refund.editPriceTip2')}}￥{{ goodsInfo.order_amount }}，{{$t('mobile.refund.editPriceTip3')}}￥0.00</div>
						</div>
					</div>
				</div>
				<div class="mapply-remark">
					<div class="remark-tit">{{ $t('mobile.refund.replenishText') }}</div>
					<div class="remark-box">
						<el-input v-model="refundForm.description" type="textarea" :autosize="{ minRows: 2, maxRows: 10 }"
							:placeholder="$t('mobile.placeholder.replenishText')" maxlength="200" show-word-limit></el-input>
						<ul class="upload-list">
							<li v-for="(item,index) in refundForm.img_urls" :key="index">
								<img :src="item" alt="">
								<i class="btn-close el-icon-error" @click="handleRemoveImg(index)"></i>
							</li>
							<li v-if="refundForm.img_urls.length < 3">
								<el-upload
									:multiple="true"
									:show-file-list="false"
									:http-request="(data)=>{httpRequest(data.file)}"
									class="upload-box"
									action="#"
									v-loading="loading"
								>
									<i class="el-icon-camera"></i>
									<div class="text">
										<p>{{ $t('mobile.actions.uploadVoucher') }}</p>
										<p>({{ $t('mobile.actions.maxLength3') }})</p>
									</div>
								</el-upload>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="mrefund-block"></div>
			<div class="mrefund-bottom">
				<div class="btn" @click="handleSubmit">{{ $t('mobile.actions.submit') }}</div>
			</div>
			<!-- 货物状态 -->
			<div class="mpop" :class="{ 'mpop-show': isPopShow.goodsStatus }">
				<div class="mpop-bg" @click="handlePopClose('goodsStatus')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('goodsStatus')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{ $t('mobile.refund.cargoStatus') }}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popGoodsStatus">
							<el-radio v-for="(item,index) in statusOption" :key="index" :label="item.value">{{ item.label }}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleGoodsStatus">{{ $t('mobile.actions.finish') }}</div>
					</div>
				</div>
			</div>
			<!-- 退款原因 -->
			<div class="mpop" :class="{'mpop-show':isPopShow.refundReason}">
				<div class="mpop-bg" @click="handlePopClose('refundReason')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('refundReason')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{ $t('mobile.refund.reason') }}</div>
					<div class="mpop-main">
						<div class="mpop-group">
							<el-radio-group v-model="popRefundReason">
								<el-radio v-for="(item,index) in reasonList" :key="index" :label="item">{{ item }}</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleRefundReason">{{ $t('mobile.actions.finish') }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchRefundGoods, refundApply, refundReason } from "@/api/mobile/order"
import { OSSUpload } from "@/utils/upload"
export default {
	filters: {
		statusFilter(status, option) {
			let str = ''
			option.forEach(item => {
				if(item.value === status) {
					str = item.label
				}
			})
			return str
		}
	},
	props: {
		isRefund: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			goodsInfo: {},
			refundForm: {
				goods_status: 0,
				img_urls: [],
				reason: '',
				description: '',
				price: 0.00
			},
			reasonData: [],
			reasonList: [],
			isPopShow: {
				goodsStatus: false,
				refundReason: false
			},
			popGoodsStatus: 0,
			popRefundReason: '',
			loading: false,
			statusOption: this.$t('mobile.options.cargoStatus')
		}
	},
	created () {
		if(!this.isRefund) {
			this.refundForm.goods_status = 3
		}
		this.getRefundGoods()
		this.getRefundReason()
	},
	methods: {
		getRefundGoods() {
			fetchRefundGoods(this.$route.params).then(response => {
				this.goodsInfo = response.data
				this.refundForm.price = response.data.order_amount
			}).catch(() => {
				this.$router.go(-1)
			})
		},
		async getRefundReason() {
			const { data } = await refundReason({ uuid: this.$store.getters.uuid })
			this.reasonData = data.reasons || []
			if(this.isRefund) {
				this.reasonList = this.reasonData[0] || []
			} else {
				this.reasonList = this.reasonData[1] || []
			}
		},
		changeGoodsStatus() {
			this.isPopShow.goodsStatus = true
		},
		changeRefundReason() {
			if(this.isRefund && !this.refundForm.goods_status > 0) {
				return false
			} else {
				this.isPopShow.refundReason = true
			}
		},
		handlePopClose(type) {
			this.isPopShow[type] = false
		},
		handleGoodsStatus() {
			if(this.popGoodsStatus>0) {
				if(this.popGoodsStatus !== 2) {
					this.reasonList = this.reasonData[1] || []
				} else {
					this.reasonList = this.reasonData[0] || []
				}
				this.$set(this.refundForm,'goods_status',this.popGoodsStatus)
			} else {
				this.$message({
					message: this.$t('mobile.errorMsg.cargoStatus'),
					customClass: 'wap-message',
					type: 'error'
				})
			}
			this.popRefundReason = ''
			this.refundForm.reason = ''
			this.isPopShow.goodsStatus = false
		},
		handleRefundReason() {
			if(this.popRefundReason) {
				this.$set(this.refundForm,'reason',this.popRefundReason)
			} else {
				this.$message({
					message: this.$t('mobile.errorMsg.refundReason'),
					customClass: 'wap-message',
					type: 'error'
				})
			}
			this.isPopShow.refundReason = false
		},
		handleEditPrice() {
			this.$refs.refundPrice.focus()
		},
		changeRefundPrice(val) {
			if(val > parseFloat(this.goodsInfo.order_amount)){
				this.refundForm.refund_price = this.goodsInfo.order_amount
			}
		},
		async httpRequest(file){
			const isType = ['image/jpeg','image/png'].includes(file.type);
			const isSize = file.size / 1024 / 1024 < 3;
			if (!isType) {
				this.$message({
					message: this.$t('mobile.errorMsg.uploadType'),
					customClass: 'wap-message',
					type: 'error'
				});
				return false;
			}
			if (!isSize) {
				this.$message({
					message: this.$t('mobile.errorMsg.uploadSize3M'),
					customClass: 'wap-message',
					type: 'error'
				});
				return false;
			}
			this.loading = true;
			const { url } = await OSSUpload(file,'other');
            if(url) {
                this.refundForm.img_urls.push(url)
            }
			this.loading = false;
		},
		handleRemoveImg(index) {
			this.refundForm.img_urls.splice(index, 1)
		},
		handleSubmit() {
			const params = {
				...this.refundForm,
				...this.$route.params,
				order_sn: this.goodsInfo.order_sn
			}
			params.img_urls = params.img_urls.join(',')
			refundApply(params).then(response => {
				const { data } = response
				this.$message({
					message: this.$t('mobile.successMsg.submit'),
					customClass: 'wap-message',
					type: 'success'
				});
				this.$router.push({ name: 'mRefundInfo', params: { id: data.apply_refund_id }})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.mrefund-apply {
	padding: 0.32rem 0.256rem;

	.mapply-group {
		border-radius: 0.32rem;
		padding: 0 0.384rem;
		background-color: #ffffff;
		margin-bottom: 0.32rem;

		.item {
			padding: 0.32rem 0;
			line-height: 0.64rem;
			border-bottom: 1px solid #e5e5e5;

			.item-label {
				font-size: 0.416rem;

				em {
					color: #ff5000;
				}
			}

			.item-next {
				color: #999999;
				float: right;
				font-size: 0.416rem;
				.black {
					color: #333333;
				}
			}

			.item-block {
				margin-top: 0.16rem;

				.price {
					font-weight: bold;
					color: #ff5000;
					font-size: 0.384rem;

					span {
						font-size: 0.544rem;
					}
				}

				::v-deep .el-input {

					.el-input-group__append,
					.el-input-group__prepend {
						background-color: transparent;
						border: none;
					}

					.el-input-group__prepend {
						padding: 0;
						color: #ff5000;
						font-weight: 600;
						font-size: 0.384rem;
						vertical-align: baseline;
					}

					.el-input-group__append {
						padding: 0;
						font-size: 0.384rem;
					}

					.el-input__inner {
						border: none;
						padding: 0;
						color: #ff5000;
						font-size: 0.544rem;
						font-weight: 600;
						height: 0.64rem;
						line-height: 0.64rem;
					}
					&.is-disabled {
						.el-input__inner {
							color: #ff5000;
							border-color: transparent;
							background-color: transparent;
						}
					}
				}

				.tip {
					color: #999999;
					font-size: 0.384rem;
					line-height: 0.544rem;
				}
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}

	.mapply-remark {
		border-radius: 0.32rem;
		background-color: #ffffff;
		padding: 0.48rem 0.384rem;

		.remark-tit {
			font-size: 0.416rem;
			font-weight: 600;
			height: 0.544rem;
			line-height: 0.544rem;
			margin-bottom: 0.48rem;
		}

		.remark-box {
			border-radius: 0.256rem;
			background-color: #f7f7f7;

			::v-deep .el-textarea {
				font-size: 0.416rem;
				.el-textarea__inner {
					padding: 0.16rem 0.384rem;
					border-color: transparent;
					background-color: transparent;
					resize: none;
				}

				.el-input__count {
					bottom: 0.16rem;
					right: 0.384rem;
					line-height: 1em;
					font-size: 0.352rem;
					background-color: transparent;
				}
			}

			.upload-list {
				padding: 0.32rem 0.384rem 0.48rem;

				li {
					margin-right: 0.384rem;
					width: 2.368rem;
					height: 2.368rem;
					display: inline-block;
					vertical-align: top;
					position: relative;

					.upload-box {
						width: 100%;
						height: 100%;
						::v-deep .el-upload {
							color: #999999;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-flow: column;
							box-sizing: border-box;
							border: 1px dashed #bdbdbd;

							i {
								font-size: 0.832rem;
							}

							.text {
								font-size: 0.352rem;
								text-align: center;
								line-height: 0.448rem;
							}
						}
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					.btn-close {
						top: -0.288rem;
						right: -0.288rem;
						color: #888888;
						font-size: 0.64rem;
						position: absolute;
					}
				}
			}
		}
	}
}

.mrefund-block {
	height: 1.664rem;
}

.mrefund-bottom {
	width: 100%;
	left: 0;
	bottom: 0;
	box-sizing: border-box;
	padding: 0.256rem 0.384rem;
	position: absolute;
	background-color: #ffffff;

	.btn {
		width: 100%;
		height: 1.152rem;
		line-height: 1.152rem;
		text-align: center;
		border-radius: 0.576rem;
		font-size: 0.448rem;
		color: #ffffff;
		background: linear-gradient(to right, #ee7e32, #f25528);
	}
}

.mpop-main {
	max-height: 8rem;
	overflow-y: auto;
}
.mpop-group {
	padding: 0.256rem 0.384rem;

	::v-deep .el-radio-group {
		display: block;

		.el-radio {
			display: block;
			margin-right: 0;
			padding: 8px 0;
			border-bottom: 1px solid #e5e5e5;

			.el-radio__inner {
				width: 0.512rem;
				height: 0.512rem;
			}

			.el-radio__inner:after {
				width: 0.192rem;
				height: 0.192rem;
			}

			.el-radio__label {
				color: #333333;
				font-size: 0.416rem;

				span {
					color: #999999;
					margin-left: 0.192rem;
					font-size: 0.384rem;
				}
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
</style>
